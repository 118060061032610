import {
	GET_VIDEOS_FAILURE,
	GET_VIDEOS_REQUEST,
	GET_VIDEOS_SUCCESS,
	GET_VIDEOS_PENDING,
} from "./actionTypes";

// NOTES

export const getVideosRequest = (payload) => {
  return {
    type: GET_VIDEOS_REQUEST,
    payload,
  };
};

export const getVideosSuccess = (payload) => {
  return {
    type: GET_VIDEOS_SUCCESS,
    payload,
  };
};

export const getVideosFailure = (payload) => {
  return {
    type: GET_VIDEOS_FAILURE,
    payload,
  };
};

export const getVideosPending = () => {
  return {
    type : GET_VIDEOS_PENDING,
  }
}
