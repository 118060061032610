import { takeLatest, call, put } from "redux-saga/effects";
import { getVideosApiCall } from "./apiCalls";
import { getVideosFailure, getVideosPending, getVideosSuccess } from "./actions";
import { GET_VIDEOS_REQUEST } from "./actionTypes";

function* getVideosWorker({ payload }) {
  try {
    yield put(getVideosPending());
    const response = yield call(getVideosApiCall, payload);
    yield put(getVideosSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getVideosFailure({ error }));
  }
}

export function* videosWatcher() {
  yield takeLatest(GET_VIDEOS_REQUEST, getVideosWorker);
}
