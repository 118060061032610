import { GET_VIDEOS_SUCCESS, GET_VIDEOS_FAILURE, GET_VIDEOS_PENDING } from "./actionTypes";

const initialState = {
  videos: [],
  count: 0,
  loading: true,
  failed: false,
  error: {},
};

function videosReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_VIDEOS_PENDING:
      return { ...state , loading : true };

    case GET_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: payload.list,
        count: payload.count,
        failed: false,
        loading: false,
      };

    case GET_VIDEOS_FAILURE:
      return { ...state, videos : [] , failed: true, loading: false, error: payload.error };

    default:
      return state;
  }
}

export default videosReducer;
