import { isPageAccessAllowed } from "../util";
import Login from "../views/auth/Login";
import RequestResetPassword from "../views/auth/RequestResetPassword";
import ResetPassword from "../views/auth/ResetPassword";
import AddBill from "../views/billing/AddBill";
import ListBillings from "../views/billing/ListBillings";
import Dashboard from "../views/Dashboard";
import ListSaloons from "../views/saloons/ListSaloons";
import ListServices from "../views/services/ListServices";
import UpsertService from "../views/services/UpsertService";
import UserScreen from "../views/user-screen";
import UserInfo from "../views/user-screen/UserInfo";
import BookAppointment from "../views/user-screen/BookAppointment";
import SaloonBookAppointment from "../views/user-screen/SaloonBookAppointment";
import AppointmentStep1 from "../views/user-screen/AppointmentStep1";
import AppointmentStep2 from "../views/user-screen/AppointmentStep2";
import BookServices from "../views/user-screen/BookServices";
import ThankYou from "../views/user-screen/ThankYou";
import ListCustomersCheckIn from "../views/customer/ListCustomersCheckIn";
import UpsertEmployee from "../views/employee/UpsertEmployee";
import ListEmployee from "../views/employee/ListEmployee";
import UpsertSaloon from "../views/saloons/UpsertSaloon";
import UpsertProject from "../views/project/UpsertProject";
import ListProject from "../views/project/ListProject";
import UpsertAccount from "../views/account/UpsertAccount";
import ListAccount from "../views/account/ListAccount";
import UpsertLocation from "../views/location/UpsertLocation";
import ListLocation from "../views/location/ListLocation";
import SaloonBookServices from "../views/user-screen/SaloonBookServices";
import EmployeeSchedule from "../views/employee/EmployeeSchedule";
import ListSchedule from "../views/employee/ListSchedule";
import EmployeeScreen from "../views/employee-screen";
import EmployeeLogin from "../views/employee/Login";
import EmployeeTimeSheet from "../views/time-sheet";
import ListLateEmployee from "../views/employee/ListLateEmployee";
import DesignDashboard from "../views/DesignDashboard";
import SaloonDashboard from "../views/Dashboard/SaloonDashboard";
import ListClockInSummary from "../views/employee/ListClockInSummary";
import PayrollReport from "../views/report/PayrollReport";
import CommissionDetailedReport from "../views/report/CommissionDetailedReport";
import TipsDetailedReport from "../views/report/TipsDetailedReport";
import SamplePage from "../views/SamplePage";
import HourlyDetailedReport from "../views/report/HourlyDetailedReport";
import ScheduleDetailedReport from "../views/report/ScheduleDetailedReport";
import ListUser from "../views/user/ListUser";
import UpsertUser from "../views/user/UpsertUser";
import SMSQueue from "../views/customer/SMSQueue";
import SalesCalendar from "../views/Dashboard/SalesCalendar";
import SmsSetting from "../views/Dashboard/SmsSetting";
import TodayLateClockOut from "../views/employee/TodayLateClockOut";
import ListVideo from "../views/video/ListVideo";

export const publicRoutes = [
  {
    path: "/login",
    component: <Login />,
    title: "Sign in",
    showHeader: true,
  },
  {
    path: "/login/:loginToken",
    component: <Login />,
    title: "Sign in",
    showHeader: true,
  },
  {
    path: "/request-reset-password",
    component: <RequestResetPassword />,
    title: "Request reset password",
    showHeader: true,
  },
  {
    path: "/reset-password/:token",
    component: <ResetPassword />,
    title: "Reset Password",
    showHeader: true,
  },
  {
    path: "/user-info",
    component: <UserInfo />,
    title: "Salon ",
    showHeader: true,
  },
  {
    path: "/book-appointment",
    component: <BookAppointment />,
    title: "Book Appointment",
  },
  {
    path: "/book-appointment/:saloon_location_id",
    component: <SaloonBookAppointment />,
    title: "Book Appointment",
	additionalClass:"bg100",
  },
  {
    path: "/appointment-step1",
    component: <AppointmentStep1 />,
    title: "Appointment Step1",
  },
  {
    path: "/appointment-step2",
    component: <AppointmentStep2 />,
    title: "Appointment Step2",
  },
  {
    path: "/book-services",
    component: <BookServices />,
    title: "Book Services",
  },
  {
    path: "/book-services/:saloon_location_id",
    component: <SaloonBookServices />,
    title: "Book Services",
  },
  {
    path: "/thank-you",
    component: <ThankYou />,
    title: "Thank You",
  	additionalClass:"bg100",

  },
  {
    path: "/employee-login",
    component: <EmployeeLogin />,
    title: "Sign in",
    showHeader: true,
  },

];

export const bookAppointmentRoute = [
  {
    path: "/book-appointment/:saloon_location_id",
    component: <SaloonBookAppointment />,
    title: "Book Appointment",
	  additionalClass:"bg100",
  },
];

export const bookServicesRoute = [
  {
    path: "/book-services/:saloon_location_id",
    component: <SaloonBookServices />,
    title: "Book Services",
    additionalClass:"bg100",
  },
]

export const customerFacingRoutes = [
  {
    path: "/user",
    component: <UserScreen />,
    title: "Salon ",
    showHeader: true,
  },
];

export const getRoutes = (permissions) => {
  return [
    {
      path: "/",
      component: <Dashboard />,
      title: "Dashboard",
      icon: "bi bi-speedometer2",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("dashboard", permissions),
    },
    {
      path: "/design-dashboard",
      component: <DesignDashboard />,
      title: "Design Dashboard",
      icon: "bi bi-speedometer2",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("dashboard", permissions),
    },
    {
      path: "/saloon-dashboard",
      component: <SaloonDashboard />,
      title: "Dashboard",
      icon: "bi bi-speedometer2",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("time-sheet", permissions),
    },
    // services
    {
      path: "/services",
      component: <ListServices />,
      title: "Services",
      icon: "bi bi-hdd-stack",
      isSidebarMenu: true,
      topAlignHeader : 'align-items-start',
      pageAccess: isPageAccessAllowed("service", permissions),
    },
    {
      path: "/services/:id",
      component: <UpsertService />,
      title: "Upsert Services",
      icon: "bi bi-hdd-stack",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("service", permissions),
    },
    {
      path: "/salons",
      component: <ListSaloons />,
      title: "Salons",
      icon: "bi bi-shop",
      isSidebarMenu: true,
      topAlignHeader : 'align-items-start',
      pageAccess: isPageAccessAllowed("saloon", permissions),
    },
    {
      path: "/salons/:id",
      component: <UpsertSaloon />,
      title: "Upsert Saloon",
      icon: "bi bi-hdd-stack",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("saloon", permissions),
  	},
    // billing
    {
      path: "/billings",
      component: <ListBillings />,
      title: "Billings",
      icon: "bi bi-cash-coin",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("billing", permissions),
    },
    {
      path: "/billings/add",
      component: <AddBill />,
      title: "Billing",
      icon: "bi bi-cash-coin",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("billing", permissions),
    },
    {
      path: "/customers-check-in",
      component: <ListCustomersCheckIn />,
      title: "Customer's Check - In",
      icon: "bi bi-person-vcard",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("customers-check-in", permissions),
    },
    {
      path: "/sms-queue",
      component: <SMSQueue />,
      title: "SMS Queue",
      icon: "bi bi-send",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("customers-check-in", permissions),
    },
    {
      path: "/employees",
      component: <ListEmployee />,
      title: "Employees",
      icon: "bi bi-person",
      isSidebarMenu: true,
      topAlignHeader : 'align-items-start',
      pageAccess: isPageAccessAllowed("employee", permissions),
    },
    {
      path: "/employees/:id",
      component: <UpsertEmployee />,
      title: "Upsert Employees",
      icon: "bi bi-person",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("employee", permissions),
    },
    {
      path: "/square-applications",
      component: <ListProject />,
      title: "Square Applications",
      icon: "bi bi-list-task",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("project", permissions),
    },
    {
      path: "/square-applications/:id",
      component: <UpsertProject />,
      title: "Upsert Square Application",
      icon: "bi bi-list-task",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("project", permissions),
    },
    {
      path: "/square-accounts",
      component: <ListAccount />,
      title: "Square Accounts",
      topAlignHeader : 'align-items-start',
      icon: "bi bi-person-bounding-box",
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("account", permissions),
    },
    {
      path: "/square-accounts/:id",
      component: <UpsertAccount />,
      title: "Upsert Square Account",
      topAlignHeader : 'align-items-start',
      icon: "bi bi-person-bounding-box",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("account", permissions),
    },
    {
      path: "/locations",
      component: <ListLocation />,
      title: "Locations",
      icon: "bi bi-geo-alt-fill",
      isSidebarMenu: true,
      topAlignHeader : 'align-items-start',
      pageAccess: isPageAccessAllowed("location", permissions),
    },
    {
      path: "/locations/:id",
      component: <UpsertLocation />,
      title: "Upsert Location",
      icon: "bi bi-geo-alt-fill",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("location", permissions),
    },
    {
      path: "/schedules",
      component: <ListSchedule />,
      title: "Schedule List",
      icon: "bi bi-person",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("employee", permissions),
		},
		{
      path: "/schedules/:id",
      component: <EmployeeSchedule />,
      title: "Schedule List",
      icon: "bi bi-person",
      isSidebarMenu: false,
      pageAccess: isPageAccessAllowed("employee", permissions),
		},
		{
      path: "/schedules/add",
      component: <EmployeeSchedule />,
      title: "Employee Schedules",
      showSideBar : false,
      icon: "bi bi-clock",
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("employee", permissions),
		},
    {
      path: "/time-sheet",
      component: <EmployeeTimeSheet />,
      title: "Time Sheet",
      icon: "bi bi-calendar2-week",
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("time-sheet", permissions),
    },
    {
      path: "/late-employees",
      component: <ListLateEmployee />,
      title: "Late Employees",
      icon: "bi bi-hourglass-bottom",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("employee", permissions),
    },
    {
      path: "/clock-in-summary",
      component: <ListClockInSummary />,
      title: "Clock In Summary",
      icon: "bi bi-person-vcard",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("employee", permissions),
    },
	{
        path: "/today-late-clock-out",
        component: <TodayLateClockOut />,
        title: "Today Late Clock Out",
        icon: "bi bi-hourglass-bottom",
        topAlignHeader : 'align-items-start',
        isSidebarMenu: true,
        pageAccess: isPageAccessAllowed("today-late-clock-out", permissions),
    },
    {
      path: "/payroll-report",
      component: <PayrollReport />,
      title: "Payroll Report",
      icon: "bi bi-currency-dollar",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("payroll-report", permissions),
    },
    {
      path: "/commission-report",
      component: <CommissionDetailedReport />,
      title: "Commission Report",
      icon: "bi bi-percent",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("commission-detailed-report", permissions),
    },
    {
      path: "/tips-detailed-report",
      component: <TipsDetailedReport />,
      title: "Tips Detailed Report",
      icon: "bi bi-cash-coin",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("tips-detailed-report", permissions),
    },
    {
      path: "/sample-page",
      component: <SamplePage />,
      title: "Sample Page",
      topAlignHeader : 'align-items-start',
      icon: "bi bi-speedometer2",
      isSidebarMenu: false,
      pageAccess: true,
    },
    {
      path: "/hourly-detailed-report",
      component: <HourlyDetailedReport />,
      title: "Hourly Detailed Report",
      icon: "bi bi-clock-history",
      topAlignHeader : 'align-items-start',
      isSidebarMenu: true,
      pageAccess: isPageAccessAllowed("hourly-detailed-report", permissions),
    },
    {
        path: "/schedule-detailed-report",
        component: <ScheduleDetailedReport />,
        title: "Schedule Detailed Report",
        icon: "bi bi-alarm",
        topAlignHeader : 'align-items-start',
        isSidebarMenu: true,
        pageAccess: isPageAccessAllowed("schedule-detailed-report", permissions),
    },
    {
        path: "/sales-summary",
        component: <SalesCalendar />,
        title: "Sales Summary",
        icon: "bi bi-calendar-day",
        topAlignHeader : 'align-items-start',
        isSidebarMenu: true,
        //pageAccess: true,
        pageAccess: isPageAccessAllowed("sales-summary", permissions),
    },
	{
        path: "/sms-setting",
        component: <SmsSetting />,
        title: "SMS Setting",
        icon: "bi bi-gear-fill",
        topAlignHeader : 'align-items-start',
        isSidebarMenu: true,
        //pageAccess: true,
        pageAccess: isPageAccessAllowed("sms-setting", permissions),
    },
	{
        path: "/videos",
        component: <ListVideo />,
        title: "Videos",
        icon: "bi bi-geo-alt-fill",
        isSidebarMenu: false,
        topAlignHeader : 'align-items-start',
        pageAccess: true,
      },
    
    /*
    {
      path: "/users",
      component: <ListUser />,
      title: "Users",
      icon: "bi bi-people",
      isSidebarMenu: false,
      topAlignHeader : 'align-items-start',
      pageAccess: isPageAccessAllowed("account", permissions),
   },
   {
      path: "/users/:id",
      component: <UpsertUser />,
      title: "Upsert User",
      icon: "bi bi-person-add",
      isSidebarMenu: false,
      topAlignHeader : 'align-items-start',
      pageAccess: isPageAccessAllowed("account", permissions),
    },
    */
  ];
};

export const employeeRoutes = [
  {
    path: "/welcome",
    component: <EmployeeScreen />,
    title: "Salon ",
    showHeader: true,
  },
];