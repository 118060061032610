import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, 
	Modal, 
	ModalHeader,
	ModalBody,
	ModalFooter,
	CardBody, Input, Row, Col, Label } from "reactstrap";
import ConfirmModal from "../../components/common/ConfirmModal";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { getVideosRequest } from "../../redux/videos/actions";
import { api, isActionPermitted , getFormatDate, USER_TYPES, DB_FORMAT } from "../../util";
import moment from "moment/moment";

const ListVideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { videos, count, loading } = useSelector((state) => state.videos);
  const { permissions , accountId } = useSelector((state) => state.user);
  const { saloons } = useSelector((state) => state.saloons);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    limit: 10,
    filter: {
      name: "",
      location: "",
      from: null, // moment().format(DB_FORMAT.DATE),
	    to: null, // moment().format(DB_FORMAT.DATE),
    },
    sort: "createdAt",
  });

  useEffect(() => {
    //if (isActionPermitted("location", "read", permissions)) {
      dispatch(getVideosRequest(paginationConfig));
      dispatch(getSaloonsRequest());
   // }
  }, [paginationConfig, permissions]);

  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };
  
  const handleVideoRowClick = (videoUrl) => {
	    setShowVideoConfirmationCodeModal(true);
	    setSelectedVideo(videoUrl);
  };

  const onDateRangeChange = ({ target }) => {
	  const { name, value } = target;
	  let pc = paginationConfig;
	  if (name === "from") {
      pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
    } else {
      pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
    }
	  setPaginationConfig({ ...pc, page: 1, limit: 10 });
  };

  const [startDate , setStartDate] = useState(paginationConfig.filter.from);
  const [endDate , setEndDate] = useState(moment().format(DB_FORMAT.DATE));
  const [showVideoConfirmationCodeModal , setShowVideoConfirmationCodeModal] = useState(false);
  const toggle = () => setShowVideoConfirmationCodeModal(!showVideoConfirmationCodeModal);

  return (
    <React.Fragment>
      <PageHeader title="Videos"/>
      <Card>
        <CardBody>
          <Row className="gy-3 gx-3">
	       <Col xl={3} md={3} sm={12} xs={12}>
	          <Label>Location</Label>
	          <Input
	            caret
	            type="select"
	            name="location"
	            value={paginationConfig.filter.location}
	            onChange={(e) => {
	              let x = paginationConfig;
	              x.filter.location = e.target.value;
	              setPaginationConfig({ ...x,  page: 1, limit: 10 });
	            }}
	          >
	            <option value={""}>All Location</option>
	            {saloons.map((_, index) => (
	              <option key={_.id} value={_.Saloon.id}>
	                {_.Saloon.name} - {_.Saloon.location}
	              </option>
	            ))}
	          </Input>
	        </Col>
            <Col xl={3} md={3} sm={6} xs={6}>
              <Label>From</Label>
              <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
            </Col>
            <Col xl={3} md={3} sm={6} xs={6}>
              <Label>To</Label>
              <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={videos}
        initialState={ accountId != 0 ? {hiddenColumns : ['Account.name']} : {} }
        columns={[
          { Header: "Saloon Name", accessor: "Saloon.name" },
          { Header: "File Name", accessor: "file_name" },
          {
              Header: "Date and Time",
              accessor: "createdAt",
              Cell: ({ row }) => {
                  return `${getFormatDate(row.original.createdAt)}`;
              },
          },
          {
              Header: "Actions",
              accessor: "actions",
              width: "20%",
              Cell: ({ row }) => {
                return (
                  <div className="d-flex gap-2">
                    <Button onClick={() => handleVideoRowClick(row.original.file_path)}>
                        <i className="bi bi-play"></i>
                      </Button>
                  </div>
                );
              },
            }
        ]}
      />
      
      {selectedVideo && (
    	        <Modal size="lg" centered isOpen={showVideoConfirmationCodeModal} toggle={toggle} >
					<ModalHeader toggle={toggle} className="text-capitalize">
						Video
					</ModalHeader>
					<ModalBody>
						<Row>
							<Col>
							<center>	
							 <video width="600" controls autoPlay>
			    	            <source src={"https://threadingpos.com/public/" + selectedVideo} type="video/mp4" />
			    	            Your browser does not support the video tag.
			    	          </video>
			    	            </center>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter tag={"div"} className="transparant border-0 bg-light bg-light">
						<Button type="button" onClick={toggle} color="transprent" className="border">
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
    	        
      	)}
      </React.Fragment>
  );
};

export default ListVideo;
